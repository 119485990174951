export default class {
    static fetchJson(url, method = 'GET', data = null, abortController = new AbortController()) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: method || 'GET',
                body: data ? JSON.stringify(data) : null,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                signal: abortController.signal,
            })
            .then(response => {
                if (response.redirected) {
                    if (response.url.match(/\/login/) && document.body.dataset.auth) {
                        window.location.reload();
                    } else {
                        throw new Error('Redirected to ' + response.url);
                    }
                } else if (response.ok) {
                    response.json().then((data) => resolve(data))
                } else {
                    if (response.status === 404) {
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Ressource introuvable'}}));
                    } else {
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Une erreur est survenue'}}));
                    }
                    reject(response);
                }
            })
            .catch(error => {
                if (!abortController.signal.aborted) { // Interruption Volontaire du Get
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Une erreur est survenue'}}));
                    reject(error);
                }
            });
        });
    }

    static debounce(func, wait, immediate = false) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };
};